import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import Header from './Header/Header'
import Footer from './Footer/Footer'
import './layout.scss'
import './base.scss'
import favicon from './favicon.ico'

const Layout = ({ children, data }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            appLink
            payomaticLocationsLink
          }
        }
        contentfulSocialLinks {
          links
        }
        contentfulDirectDepositPage {
          id
          fields {
            navLabel
            slug
          }
        }
        contentfulPayBillsPage {
          id
          fields {
            navLabel
            slug
          }
        }
      }
    `}
    render={data => (
      <Fragment>
        <Helmet>
          <link rel="shortcut icon" type="image/x-icon" href={favicon} />
          <script>
            {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-K9VX7FS');`}
          </script>

          {/* Global site tag (gtag.js) - Google Analytics */}
          <script
            async
            src="https://www.googletagmanager.com/gtag/js?id=UA-118713750-1"
          />
          <script>
            {`window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());

    gtag('config', 'UA-118713750-1');`}
          </script>
        </Helmet>
        {/* TODO: Add satic query for navigation */}
        <Header data={data} />
        {children}
        <Footer data={data} />
      </Fragment>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
