import React, { Component } from 'react'
import { Link } from 'gatsby'

import logo from './inPOWER_BLACK.svg'
import logoWhite from '../../images/inPOWER_reverse.svg'

import styles from './header.module.scss'

export default class Header extends Component {
  state = {
    menuOpen: false
  }

  handeToggleMenu = () => {
    this.setState({
      menuOpen: !this.state.menuOpen
    })
  }

  render() {
    const {
      data: {
        contentfulPayBillsPage: pb,
        contentfulDirectDepositPage: dd,
        site: {
          siteMetadata: { payomaticLocationsLink, appLink }
        }
      }
    } = this.props

    return (
      <header className={styles.header}>
        <div className={styles.headerInner}>
          <Link to="/">
            <img alt="inPower Logo" className={styles.logo} src={logo} />
          </Link>
          <div className={styles.menu} onClick={this.handeToggleMenu}>
            <div className={styles.menuRow}>
              <span className={styles.menuSquare} />
              <span className={styles.menuSquare} />
              <span className={styles.menuSquare} />
            </div>
            <div className={styles.menuRow}>
              <span className={styles.menuSquare} />
              <span className={styles.menuSquare} />
              <span className={styles.menuSquare} />
            </div>
            <div className={styles.menuRow}>
              <span className={styles.menuSquare} />
              <span className={styles.menuSquare} />
              <span className={styles.menuSquare} />
            </div>
          </div>
          <nav className={styles.nav}>
            <Link to={`/${dd.fields.slug}`}>{dd.fields.navLabel}</Link>
            <Link to={`/${pb.fields.slug}`}>{pb.fields.navLabel}</Link>
            {/* <Link to="/blog">Blog</Link> */}
            {/* <Link to="/spanish">Spanish</Link> */}
            <div className={styles.navCta}>
              {/* <a
                target="_blank"
                rel="noopener noreferrer"
                className={cx(styles.navButton, styles.navButtonInvert)}
                href={payomaticLocationsLink}
              >
                Find a store
              </a> */}
              <a
                target="_blank"
                rel="noopener noreferrer"
                className={styles.navButton}
                href={payomaticLocationsLink}
              >
                Get Started
              </a>
            </div>
          </nav>
        </div>
        {this.state.menuOpen && (
          <div className={styles.mobileMenu}>
            <Link className={styles.mobileMenuLogo} to="/">
              <img alt="inPower Logo" src={logoWhite} />
            </Link>
            <span
              className={styles.mobileMenuCloseIcon}
              onClick={this.handeToggleMenu}
            >
              ✕
            </span>
            <nav className={styles.mobileNav}>
              <a href={appLink}>Download Mobile App</a>
              <Link to={`/${dd.fields.slug}`}>{dd.fields.navLabel}</Link>
              <Link to={`/${pb.fields.slug}`}>{pb.fields.navLabel}</Link>
              {/* <Link to="/blog">Blog</Link> */}
              {/* <a
                target="_blank"
                rel="noopener noreferrer"
                href={payomaticLocationsLink}
              >
                Find a store
              </a> */}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={payomaticLocationsLink}
              >
                Get Started
              </a>
              {/* <Link to="/spanish">Translate site to Spanish</Link> */}
            </nav>
          </div>
        )}
      </header>
    )
  }
}
