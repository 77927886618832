import React from 'react'
import classnames from 'classnames'

import styles from './button.module.scss'

export default function Button({ label, className, invert, href, ...rest }) {
  if (!!href) {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        className={classnames(styles.button, className, {
          [styles.buttonInvert]: invert
        })}
        {...rest}
      >
        {label}
      </a>
    )
  }

  return (
    <button
      className={classnames(styles.button, className, {
        [styles.buttonInvert]: invert
      })}
      {...rest}
    >
      {label}
    </button>
  )
}
